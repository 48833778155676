// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/constantia.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/analogue/analogue-italic-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/analogue/analogue-italic-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/analogue/analogue-italic-webfont.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/analogue/analogue-italic-webfont.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/analogue/analogue-webfont.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/analogue/analogue-webfont.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/analogue/analogue-webfont.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/analogue/analogue-webfont.svg");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/playfair/PlayfairDisplay-Italic.ttf");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../fonts/playfair/PlayfairDisplay-Regular.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#analogueitalic" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___, { hash: "#analogueregular" });
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Constantia;src:local(\"Constantia\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-display:swap}.constantia{font-family:Constantia}@font-face{font-family:\"analogueitalic\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"svg\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"analogueregular\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"svg\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"Playfair Display Italic\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"truetype\");font-weight:700;font-style:italic;font-display:swap}@font-face{font-family:\"Playfair Display\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"truetype\");font-weight:400;font-style:normal;font-display:swap}.analogue{font-family:\"analogueregular\"}.playfair-italic{font-family:\"Playfair Display Italic\"}.playfair{font-family:\"Playfair Display\"}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
